import {
    BasicFieldOperations,
    BasicObjectDefinitionFactory,
    DataObjectDefinition,
    FieldType,
    ObjectDefinitionRegistry
} from "browser-state-management";
import debug from "debug";
import {STATE_NAMES} from "./AppTypes";

const logger = debug('definitions');

export class ObjectDefinitions {
    public static setupDataObjectDefinitions() {
        const basicOperations = BasicFieldOperations.getInstance();

        const providerDef: DataObjectDefinition = ObjectDefinitionRegistry.getInstance().addDefinition(STATE_NAMES.providers, 'Providers', true, true, false, '_id');
        BasicObjectDefinitionFactory.getInstance().addStringFieldToObjDefinition(providerDef, "name", "Name", FieldType.text, true, "Username");
        BasicObjectDefinitionFactory.getInstance().addStringFieldToObjDefinition(providerDef, "isCurrent", "Active?", FieldType.boolean, false, "Is this a current user?");
        BasicObjectDefinitionFactory.getInstance().addStringFieldToObjDefinition(providerDef, "providerNo", "Provider Number", FieldType.text, false, "Provider Number");
        logger(`Providers type data object definition`);
        logger(providerDef);

        const appointmentDef: DataObjectDefinition = ObjectDefinitionRegistry.getInstance().addDefinition(STATE_NAMES.appointments, "Appointments", true, true, false, "_id");
        BasicObjectDefinitionFactory.getInstance().addStringFieldToObjDefinition(appointmentDef, "start", "Appointment Details", FieldType.date, false);//, "Country");
    }
}
