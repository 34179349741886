import debug from 'debug';
import {
    AggregateStateManager,
    AsyncStateManagerWrapper,
    isSameMongo,
    MemoryBufferStateManager,
    RESTApiStateManager,
    StateManager,
    StateTimingManager
} from "browser-state-management";
import {API_Config, STATE_NAMES} from "./AppTypes";
import {ObjectDefinitions} from "./ObjectDefinitions";


const logger = debug('controller-ts');
const loggerDetail = debug('controller-ts-detail');


export default class Controller {


    private static _instance: Controller;
    protected applicationView: any;
    protected clientSideStorage: any;
    protected config: any;
    protected stateManager: StateManager;
    private timingManager: StateTimingManager;

    private constructor() {
    }

    public static getInstance(): Controller {
        if (!(Controller._instance)) {
            Controller._instance = new Controller();
        }
        return Controller._instance;
    }


    connectToApplication(applicationView: any, clientSideStorage: any) {
        this.applicationView = applicationView;
        this.clientSideStorage = clientSideStorage;
        // setup the API calls

        const restSM = RESTApiStateManager.getInstance();
        restSM.initialise([
            {
                stateName: STATE_NAMES.providers,
                serverURL: '',
                api: API_Config.providers,
                isActive: true,
                idField: '_id',
                find: false,
                findAll: true,
                create: false,
                update: false,
                destroy: false,
                lastModified: false
            },
            {
                stateName: STATE_NAMES.bookings,
                serverURL: '',
                api: API_Config.bookings,
                isActive: true,
                idField: '_id',
                find: false,
                findAll: true,
                create: true,
                update: false,
                destroy: false,
                lastModified: false
            },
            {
                stateName: STATE_NAMES.sites,
                serverURL: '',
                api: API_Config.sites,
                isActive: true,
                idField: '_id',
                find: false,
                findAll: true,
                create: true,
                update: false,
                destroy: false,
                lastModified: false
            },

        ]);


        const aggregateSM = new AggregateStateManager(isSameMongo);
        const memorySM = new MemoryBufferStateManager(isSameMongo);
        const asyncREST = new AsyncStateManagerWrapper(aggregateSM, restSM, isSameMongo);

        aggregateSM.addStateManager(memorySM, [], false);
        aggregateSM.addStateManager(asyncREST, [], false);
        this.stateManager = aggregateSM;

        this.timingManager = new StateTimingManager(this.stateManager);


        this.timingManager.addCollectionToTimer(STATE_NAMES.providers, "Providers");
        this.timingManager.addCollectionToTimer(STATE_NAMES.bookings, "Bookings");
        this.timingManager.addCollectionToTimer(STATE_NAMES.sites, "Sites");

        // data objects
        ObjectDefinitions.setupDataObjectDefinitions();


        return this;
    }

    public getTimingManager(): StateTimingManager {
        return this.timingManager;
    }


    /*
        Get the base data for the application (users, entries)
    */
    public onDocumentLoaded(): void {
        logger('Initialising data state');

        this.getStateManager().getStateByName(STATE_NAMES.providers);
        this.getStateManager().getStateByName(STATE_NAMES.bookings);
        this.getStateManager().getStateByName(STATE_NAMES.sites);

    }

    public getStateManager(): StateManager {
        return this.stateManager;
    }


}

