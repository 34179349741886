
export const STATE_NAMES = {
    sites:'sites',
    providers:'providers',
    bookings: 'bookings',
    appointments: 'appointments',
}

export const API_Config = {
    sites:'/online-booking/sites',
    providers:'/online-booking/providers',
    bookings: '/online-booking/bookings',

};

export const APP_Constants = {
    AppointmentTypeOnlineBooking:'Online Booking'
}

