/** @jsx jsxCreateElement */
/*** @jsxFrag jsxCreateFragment */
import debug from 'debug';
import {StateTimerListener} from "browser-state-management";
import {setOptions} from "@mobiscroll/javascript";
import Controller from "./Controller";
import {BookingDetail} from "./BookingDetail";


const logger = debug('app');

export default class App implements StateTimerListener {
    private static _instance: App;


    public static getInstance(): App {
        if (!(App._instance)) {
            App._instance = new App();
        }
        return App._instance;
    }

    private thisEl: HTMLDivElement | null = null;


    public constructor() {
        // event handlers
        Controller.getInstance().connectToApplication(this, window.localStorage);
    }


    onDocumentLoaded(): void {
        logger('document loaded');
        // @ts-ignore
        this.thisEl = document.getElementById('root');
        BookingDetail.getInstance().onDocumentLoaded();
        Controller.getInstance().getTimingManager().addListener(this);
        Controller.getInstance().onDocumentLoaded();
    }




    stateLoaded(name: string, displayName: string) {
    }


    allConfiguredStatesAreLoaded(): void {
        // setup key bindings
        console.log('app')
        Controller.getInstance().getStateManager().fireStateChangedForAllStates();
    }



}

// localStorage.debug = 'macro-evaluator macro-evaluator:detail app api-ts-results controller data-object-listener-helper state-timing-manager print-queue-controller socket-listener';
localStorage.debug = 'app state-timing-manager api-ts-results controller';
localStorage.plugin = 'chat';//api-ts

debug.log = console.info.bind(console);

$(function () {
    setOptions({
        theme: 'ios',
        themeVariant: 'light'
    });

    // @ts-ignore
    mobiscroll5.setOptions({
        theme: 'ios',
        themeVariant: 'light'
    });

    App.getInstance().onDocumentLoaded();
});
