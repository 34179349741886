import {datepicker, getInst, select} from "@mobiscroll/javascript";

import moment from "moment";
import axios from "axios";

import debug from "debug";
import {
    NotificationContent,
    NotificationManager,
    NotificationType,
    StateChangeListener
} from "browser-state-management";
import {API_Config, STATE_NAMES} from "./AppTypes";
import Controller from "./Controller";
import {v4} from "uuid";

const logger = debug('booking-detail-view');



export class BookingDetail implements StateChangeListener{

    private static _instance: BookingDetail;
    private sitesDropdown: any|null;
    private providersDropdown: any|null;
    private timeDropdown: any|null;
    private nameInput: HTMLInputElement;
    private contactNumberInput: HTMLInputElement;
    private noteInput: HTMLInputElement;
    private dateInput: HTMLInputElement;
    private timeInput: HTMLSelectElement;
    private telehealthInput: HTMLInputElement;
    private bookings: any;
    private datePicker: any|null;
    private providersLoaded: boolean = false;
    private bookingsLoaded: boolean = false;
    private sitesLoaded: boolean = false;
    private providers: any[] = [];
    private sites: any[] = [];

    private provider:string = '';
    private date:number = 0;
    private time:string = '';
    private siteId:string = '';

    private constructor() {
        Controller.getInstance().getStateManager().addChangeListenerForName(STATE_NAMES.bookings,this);
        Controller.getInstance().getStateManager().addChangeListenerForName(STATE_NAMES.sites,this);
        Controller.getInstance().getStateManager().addChangeListenerForName(STATE_NAMES.providers,this);
    }

    public static getInstance(): BookingDetail {
        if (!(BookingDetail._instance)) {
            BookingDetail._instance = new BookingDetail();
        }
        return BookingDetail._instance;
    }

    public onDocumentLoaded() {

        this.nameInput = <HTMLInputElement>document.getElementById('booking-name');
        this.contactNumberInput = <HTMLInputElement>document.getElementById('booking-contact-number');
        this.noteInput = <HTMLInputElement>document.getElementById('booking-note');
        this.dateInput = <HTMLInputElement>document.getElementById('booking-date');
        this.timeInput = <HTMLSelectElement>document.getElementById('booking-time');
        this.telehealthInput = <HTMLInputElement>document.getElementById('booking-telehealth');
        this.setupDatePicker();
        this.setupTimeDropdown([]);
        this.nameInput.value = '';
        this.contactNumberInput.value = '';
        logger('Completed setup of detail modal for bookings');
        document.getElementById('booking-book').addEventListener('click',(event) => {

            event.preventDefault();
            event.stopPropagation();
            if (BookingDetail.getInstance().isValid()) {
                const data = new FormData(<HTMLFormElement>document.getElementById('online-booking'));
                // @ts-ignore
                for (const [key, value] of data) {
                    console.log( `${key}: ${value}`);
                }

                const now = parseInt(moment().format('YYYYMMDDHHmmss'));


                axios({
                    method: "post",
                    url: API_Config.bookings,
                    data: {
                        provider: this.provider,
                        start:this.date,
                        time:this.time,
                        name:this.nameInput.value,
                        contact:this.contactNumberInput.value,
                        note:this.noteInput.value,
                        telehealth:this.telehealthInput.checked,
                        siteId:this.siteId,
                        created:now,
                        modified:now,
                        "g-recaptcha-response":data.get("g-recaptcha-response")
                    },
                    headers: {
                        "Content-Type": "application/json",
                    },
                }).then((result:any) => {
                    if (result.status === 200) {
                        window.location.href = '/booking-complete';
                    }
                }).catch((err:any) => {
                    switch (err.response.status) {
                        case 501: {
                            const notification: NotificationContent = {
                                duration: 5000,
                                id: v4(),
                                message: 'Booking date and time taken by another person.',
                                source: {
                                    name: 'Booking'
                                },
                                title: 'Booking',
                                type: NotificationType.warning

                            }
                            NotificationManager.getInstance().show(notification);
                            break;
                        }
                        case 503: {
                            const notification: NotificationContent = {
                                duration: 5000,
                                id: v4(),
                                message: 'ReCaptcha Failed, please (re)tick the box.',
                                source: {
                                    name: 'Booking'
                                },
                                title: 'Booking',
                                type: NotificationType.warning

                            }
                            NotificationManager.getInstance().show(notification);
                            break;
                        }
                        default: {
                            const notification: NotificationContent = {
                                duration: 5000,
                                id: v4(),
                                message: 'Unable to book appointment at this time.',
                                source: {
                                    name: 'Booking'
                                },
                                title: 'Booking',
                                type: NotificationType.warning

                            }
                            NotificationManager.getInstance().show(notification);
                        }
                    }
                });

            }
        })


    }

    protected isValid():boolean {
        let result:boolean = true;
        if (this.siteId.trim().length === 0) {
            result = false;
            const notification: NotificationContent = {
                duration: 3000,
                id: v4(),
                message: 'Please select a Site.',
                source: {
                    name: 'Booking'
                },
                title: 'Booking',
                type: NotificationType.info
            }
            NotificationManager.getInstance().show(notification);
        }
        if (this.provider.trim().length === 0) {
            result = false;
            const notification: NotificationContent = {
                duration: 3000,
                id: v4(),
                message: 'Please select a Provider.',
                source: {
                    name: 'Booking'
                },
                title: 'Booking',
                type: NotificationType.info
            }
            NotificationManager.getInstance().show(notification);
        }
        if (this.date === 0) {
            result = false;
            const notification: NotificationContent = {
                duration: 3000,
                id: v4(),
                message: 'Please select a booking date.',
                source: {
                    name: 'Booking'
                },
                title: 'Booking',
                type: NotificationType.info
            }
            NotificationManager.getInstance().show(notification);
        }
        if (this.time.trim().length === 0) {
            result = false;
            const notification: NotificationContent = {
                duration: 3000,
                id: v4(),
                message: 'Please select a booking time.',
                source: {
                    name: 'Booking'
                },
                title: 'Booking',
                type: NotificationType.info
            }
            NotificationManager.getInstance().show(notification);
        }
        if (this.nameInput.value.trim().length === 0) {
            result = false;
            const notification: NotificationContent = {
                duration: 3000,
                id: v4(),
                message: 'Please enter a name for the booking.',
                source: {
                    name: 'Booking'
                },
                title: 'Booking',
                type: NotificationType.info
            }
            NotificationManager.getInstance().show(notification);
        }
        if (this.contactNumberInput.value.trim().length === 0) {
            result = false;
            const notification: NotificationContent = {
                duration: 3000,
                id: v4(),
                message: 'Please enter a contact number for the booking.',
                source: {
                    name: 'Booking'
                },
                title: 'Booking',
                type: NotificationType.info
            }
            NotificationManager.getInstance().show(notification);
        }
        return result;
    }

    protected setupDatePicker() {
        this.datePicker = datepicker(document.getElementById("booking-date"), {
            controls: ['calendar'],
            display:"anchored",
            dateFormat: 'DD/MM/YYYY',
            dayNamesMin: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
            showWeekNumbers: false,
            onChange: (event: any, inst: any) => {
                BookingDetail.getInstance().setSelectedDate(event.valueText);
            }
        });
    }



    public setSelectedProvider(provider:string):void {
        this.provider = provider;
        this.updateFilters();
    }

    public setSelectedSite(site:string):void {
        this.siteId = site;
        this.updateFilters();

    }

    public setBookingsForDefaultProvider():void {
        if (this.bookingsLoaded && this.providersLoaded && this.sitesLoaded) {
            if (this.providers.length === 1) {
                BookingDetail.getInstance().updateFilters();
            }
        }
    }

    public setSelectedDate(date:string):void {
        this.date = parseInt(moment(date,'DD/MM/YYYY').format('YYYYMMDD'));
        this.updateFilters();
    }

    public setSelectedTime(time:string,isTelehealth:boolean):void {
        this.time = time;

        // this.telehealthInput.checked = isTelehealth;
        const checkbox = getInst(this.telehealthInput);
        // @ts-ignore
        checkbox.checked = isTelehealth;
        // this.telehealthInput.disabled = isTelehealth;
        // @ts-ignore
        checkbox.setOptions({disabled:isTelehealth});

    }

    protected getBookingsForProviderAndSite():any[] {
        const result:any[] = [];
        if (this.siteId.trim().length > 0) {
            this.bookings.forEach((booking:any) => {
                if ((booking.provider === this.provider) && (booking.siteId === this.siteId)) {
                    if (booking.time[0] === '0') {
                        booking.isMorning = true;
                    }
                    else if ((booking.time[0] === '1') && (booking.time[1] === '0' || booking.time[1] === '1')) {
                        booking.isMorning = true;
                    }
                    else {
                        booking.isMorning = false;
                    }
                    result.push(booking);
                }
            });
        }

        return result;
    }

    protected getBookingsForDateForProvider(bookingsForProvider:any[]):any[] {
        const result:any[] = [];
        bookingsForProvider.forEach((booking:any) => {
            if (booking.start === this.date) {
                result.push(booking);
            }
        });
        return result;
    }

    protected setMarkedDaysForBookings(bookings:any[]):void {
        const marked:any[] = [];
        bookings.forEach((booking) => {
            let colour = "#7e56bd";
            if (booking.isMorning) {
                colour = "#ffc400";
            }
            const foundIndex = marked.findIndex((mark) => ((mark.start === booking.start) && (mark.isMorning === booking.isMorning)));
            if (foundIndex < 0) {
                console.log(booking);
                marked.push({
                    start: booking.start,
                    isMorning: booking.isMorning,
                    date: moment(booking.start, 'YYYYMMDD'),
                    color: colour
                });
            }
        });

        console.log(marked);
        const cleanedMarked:any[] = [];
        const disabledDates:any[] = [];
        /*
        invalid: [
		'2022-12-24',
		'2022-12-27',
		{
			start: '2022-12-29',
			end: '2023-01-05'
		},
		{
			recurring: {
				repeat: 'weekly',
				weekDays: 'SA,SU'
			}
		}
	]
         */
        const preTodayStartDate = moment().subtract(3,'months').format('YYYY-MM-DD');
        const preTodayEndDate = moment().subtract(1,'day').format('YYYY-MM-DD');
        const postThreeMonthsStartDate  = moment().add(3,'months').format('YYYY-MM-DD');
        const postThreeMonthsEndDate  = moment().add(20,'years').format('YYYY-MM-DD');
        const invalidDates:any[] = [];
        invalidDates.push({
            start: preTodayStartDate,
            end: preTodayEndDate
        });
        invalidDates.push({
            start:postThreeMonthsStartDate,
            end: postThreeMonthsEndDate
        })
        let index = 0;
        while (index < 90) {
            const possibleInvalidDate = parseInt(moment().add(index,'days').format('YYYYMMDD'));
            const foundIndex = marked.findIndex((mark:any) => mark.start === possibleInvalidDate);
            if (foundIndex < 0) {
                invalidDates.push(moment().add(index,'days').format('YYYY-MM-DD'));
            }
            index++;
        }
        marked.forEach((mark) => {
            cleanedMarked.push({
                date: mark.date,
                color: mark.color
            })
        });
        console.log(cleanedMarked);
        this.datePicker.setOptions({
            marked:cleanedMarked,
            invalid:invalidDates
        });
    }

    protected updateFilters():void {
        if (this.provider.trim().length > 0) {
            // mark available days for bookings
            const bookingsForProvider = this.getBookingsForProviderAndSite();
            this.setMarkedDaysForBookings(bookingsForProvider);
            if (this.date > 0) {
                const bookingsForDate = this.getBookingsForDateForProvider(bookingsForProvider);
                this.setupTimeDropdown(bookingsForDate);
            }
            else {
                this.timeInput.value = '';
            }

        }
        else {
            this.timeInput.value = '';
            this.dateInput.value = '';
        }


    }


    public setupProviderDropdown(providers: any[]) {
        // add the patient search values to the data of the select dropdown
        this.providersDropdown = select('#booking-provider', {
            data: providers,
            onChange: (event: any, inst: any) => {
                BookingDetail.getInstance().setSelectedProvider(event.value);
            }
        });

        if (providers.length === 1) {
            this.providersDropdown.setVal(providers[0].value);
            BookingDetail.getInstance().setSelectedProvider(providers[0].value);
        }
    }

    public setupSitesDropdown(sites: any[]) {
        this.sitesDropdown = select('#booking-site', {
            data: sites,
            onChange: (event: any, inst: any) => {
                BookingDetail.getInstance().setSelectedSite(event.value);
            }
        });

        if (sites.length === 1) {
            this.sitesDropdown.setVal(sites[0].value);
            BookingDetail.getInstance().setSelectedSite(sites[0].value);
        }
    }

    private static APPOINTMENT_TYPE_Telehealth = 'Telehealth Call';

    protected setupTimeDropdown(bookings:any[]):void {
        const times: any[] = [];

        bookings.forEach((booking: any) => {
            let displayText = `${booking.time[0]}${booking.time[1]}:${booking.time[2]}${booking.time[3]}`;
            if (booking.type === BookingDetail.APPOINTMENT_TYPE_Telehealth) {
                displayText += ' (Telehealth)';
            }

            times.push({
                text: displayText,
                value: booking.time,
                id: booking.time,
                name: booking.time
            });
        });
        this.timeDropdown = select('#booking-time', {
            data: times,
            onChange: (event: any, inst: any) => {
                console.log(event);
                BookingDetail.getInstance().setSelectedTime(event.value,event.valueText.includes('Telehealth'));
            }
        });


    }

    filterResults(managerName: string, name: string, filterResults: any): void {
    }

    foundResult(managerName: string, name: string, foundItem: any): void {
    }

    getListenerName(): string {
        return "";
    }

    itemNotModified(managerName: string, name: string, item: any): void {
    }

    stateChanged(managerName: string, name: string, newValue: any): void {
        switch(name) {
            case STATE_NAMES.providers: {
                this.providers = [];


                newValue.forEach((provider: any) => {
                    if (provider.isCurrent) this.providers.push({
                        text: provider.name,
                        value: provider.name,
                        id: provider.name,
                        name: provider.name
                    });
                });

                this.providersLoaded = true;
                this.setupProviderDropdown(this.providers);
                break;
            }
            case STATE_NAMES.bookings: {
                this.bookings = newValue;
                this.bookingsLoaded = true;
                this.setBookingsForDefaultProvider();
                break;
            }
            case STATE_NAMES.sites: {
                this.sites = [];

                const now = parseInt(moment().format('YYYYMMDD'));

                newValue.forEach((site: any) => {
                    if (site.endDate >= now) {
                        this.sites.push({
                            text: site.name,
                            value: site._id,
                            id: site._id,
                            name: site.name
                        });
                    }
                });

                this.sitesLoaded = true;
                this.setupSitesDropdown(this.sites);
                break;
            }
        }
    }

    stateChangedItemAdded(managerName: string, name: string, itemAdded: any): void {
    }

    stateChangedItemRemoved(managerName: string, name: string, itemRemoved: any): void {
    }

    stateChangedItemUpdated(managerName: string, name: string, itemUpdated: any, itemNewValue: any): void {
    }



}
